import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData } from '@/auth/utils'

import adminAuth from './routes/admin-app/auth'
import adminDashboard from './routes/admin-app/dashboard'
import clientAuth from './routes/client-app/auth'
import clientDashboard from './routes/client-app/dashboard'


import pages from './routes/pages'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/', redirect: { name: 'dashboard' } },
       ...pages,
       ...adminAuth,
       ...adminDashboard,
       ...clientAuth,
       ...clientDashboard,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  let hasRequiredAuth=(requiredRoles)=>{
    const userData=getUserData()
    return requiredRoles.includes(userData.role)
  }
  
  const isLoggedIn = isUserLoggedIn()

  if(to.meta.requiresAuth && !isLoggedIn) {
    next({name: 'auth-login'})
  }else if((to.meta.requiresAuth && isLoggedIn) && !hasRequiredAuth(to.meta.roles)){
    next({name: 'misc-not-authorized'})
  }else{
    next()
  }
  
  // if (!canNavigate(to)) {
  //   console.log('not navigate')
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }
  // next('/')

})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
