export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/authentication/Login'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/authentication/Register'),
        meta: {
                    layout: 'full',
                    resource: 'Auth',
                    redirectIfLoggedIn: true,
                },
    },
    {
        path: '/reset-password/:email/:token',
        name: 'reset-password',
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
        component: () => import('@/views/authentication/ResetPassword'),
    },
    {
        path: '/account-verification',
        name: 'account-verification',
        component: () => import('@/views/authentication/AccountVerification'),
        meta: {
                    layout: 'full',
                    resource: 'Auth',
                },
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
        component: () => import('@/views/authentication/ForgetPassword'),
    },
    {
        path: '/signup-via-invite/:email/:token',
        name: 'signup-via-invite',
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
        component: () => import('@/views/authentication/SignupViaInvite'),
    },
    {
        path: '/login-as',
        name: 'login-as',
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
        component: () => import('@/views/authentication/LoginAs'),
    }
]