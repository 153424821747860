import http from "@/utils/http";

export async function set_fcm_token(data){
    const req = (await http.post(`/notification/token`,data ));
    return req.data;
}

export async function get_notifications(){
    const req = (await http.get(`/notification` ));
    return req.data;
}

export async function read_notifications(data){
    const req = (await http.put(`/notification/markasread`,data ));
    return req.data;
}