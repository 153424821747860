export default [
    {
      path: '/dashboard',
      name: 'dashboard',
      meta:{requiresAuth:true, roles:['client','vendor', 'service_provider','broker'], title: 'Dashboard'},
      component: () => import('@/views/Dashboard.vue'),
    },
    {
      path: '/documents',
      name: 'documents',
      meta:{requiresAuth:true, roles:['client','vendor', 'service_provider','broker'],title: 'Documents'},
      component: () => import('@/views/documents/Index.vue'),
    },
    {
      path: '/documents/:folder/:id',
      name: 'documents-folder',
      meta:{requiresAuth:true, roles:['client','vendor', 'service_provider','broker'],title: 'Folder', navActiveLink: 'documents'},
      component: () => import('@/views/documents/Folder.vue'),
    },
    {
      path: '/documents/:folder/:folderId/:subFolder/:id',
      name: 'documents-subfolder',
      meta:{requiresAuth:true, roles:['client','vendor', 'service_provider','broker'],title: 'SubFolder', navActiveLink: 'documents'},
      component: () => import('@/views/documents/SubFolder.vue'),
    },
    {
      path: '/document/details/:id',
      name: 'document-details',
      meta:{requiresAuth:true, roles:['client','vendor', 'service_provider','broker'],title: 'Send Document for Signatures', navActiveLink: 'documents'},
      component: () => import('@/views/documents/DocumentDetails.vue'),
    },
    {
      path: '/documents/recieved',
      name: 'recieved-documents',
      meta:{requiresAuth:true, roles:['client'],title: 'Documents', navActiveLink: 'documents'},
      component: () => import('@/views/documents/RecievedDocument.vue'),
    },
    {
      path: '/documents/sent',
      name: 'send-documents',
      meta:{requiresAuth:true, roles:['service_provider',],title: 'Send Document for Signatures', navActiveLink: 'documents'},
      component: () => import('@/views/documents/SentDocument.vue'),
    },
    {
      path: '/invoices',
      name: 'invoices',
      meta:{requiresAuth:true, roles:['client','vendor','service_provider','broker'], title: 'Invoices'},
      component: () => import('@/views/invoices/Index.vue'),
    },
    {
      path:'/invoice/details/:invoice/:id',
      name: 'invoice-details',
      component: () => import('@/views/invoices/InvoiceDetails.vue'),
      meta:{requiresAuth:true, roles:['client', 'service_provider'],title: 'Invoice Details', navActiveLink: 'invoices'}
    },
    {
      path:'/invoice/pay/:invoice',
      name: 'pay-invoice',
      component: () => import('@/views/invoices/PayInvoice.vue'),
      meta:{requiresAuth:true, roles:['client','vendor', 'service_provider','broker'],title: 'Pay Invoice', navActiveLink: 'invoices'}
    },
    {
      path: '/payments',
      name: 'payments',
      meta:{requiresAuth:true, roles:['client','vendor','service_provider','broker'], title: 'Payments'},
      component: () => import('@/views/Payments.vue'),
    },
    // {
    //   path: '/subscription',
    //   name: 'subscription',
    //   meta:{requiresAuth:true, roles:['client','vendor','service_provider','broker'], title: 'Subscription'},
    //   component: () => import('@/views/subscriptions/Index.vue'),
    // },
    {
      path: '/subscription/upgrade-plan/:plan',
      name: 'subscription-upgrade-plan',
      meta:{requiresAuth:true, roles:['client','vendor','service_provider','broker'], title: 'Upgrade Plan', navActiveLink: 'settings'},
      component: () => import('@/views/subscriptions/UpgradePlan.vue'),
    },
    {
      path: '/archived',
      name: 'archive',
      meta:{requiresAuth:true, roles:['client','vendor','service_provider','broker'], title: 'Archived'},
      component: () => import('@/views/Backup.vue'),
    },
    {
      path: '/settings',
      name: 'settings',
      meta:{requiresAuth:true, roles:['client','vendor','service_provider','broker'], title: 'Settings'},
      component: () => import('@/views/Settings.vue'),
    },
  ]
  