import axios from 'axios'

// Create instance called instance
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});
// var token = localStorage.getItem('token');

// http.defaults.headers.common['Authorization'] = 'Bearer ' + token;

// // declare a response interceptor
// http.interceptors.response.use((response) => {
//     return response;
// }, error => {
//     // handle the response error

//     if (error.response.status == 401) {
//         window.location.href = '/login';
//     } else {
//         return Promise.reject(error);
//     }
// });
    const authService = {
        refreshToken:async () => {
            try {
                const refreshToken = localStorage.getItem("refreshToken");
                const response = (await axios.get(`${process.env.VUE_APP_BASE_URL}auth/refresh`, {headers: {Authorization:`Bearer ${refreshToken}`}})).data
                const { accessToken} = response.data;
                
                // Update the access token and refresh token in localStorage
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
                
                // Retry the original API request with the new access token
                return true;
            } catch (error) {
                window.location.href = '/login';
                // Handle token refresh error (e.g., logout the user)
                return false;
            }
            }
    }
    http.interceptors.request.use(
        (config) => {
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    http.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
                // if(error.response.status===403){
                //     window.location.href = '/error-404';
                //     //page not found
                //     return false;
                // }
            // Handle token expiration and retry the request with a refreshed token
                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const isRefreshSuccessful = await authService.refreshToken();
                    if (isRefreshSuccessful) {
                        return http(originalRequest);
                    }
                }
            return Promise.reject(error.response.data);
        }
    );
    
    
export default http;