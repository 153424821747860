export default [
    {
      path: '/admin/dashboard',
      name: 'admin-dashboard',
      meta:{requiresAuth:true, roles:['admin'], title: 'Dashboard'},
      component: () => import('@/views/admin/dashboard/Dashboard.vue'),
    },
    {
      path: '/admin/users',
      name: 'admin-manage-users',
      meta:{requiresAuth:true, roles:['admin'], title: 'Manage Users'},
      component: () => import('@/views/admin/dashboard/Users.vue'),
    },
    {
      path: '/admin/documents',
      name: 'admin-documents',
      meta:{requiresAuth:true, roles:['admin'], title: 'Documents'},
      component: () => import('@/views/admin/dashboard/Documents.vue'),
    },
    {
      path: '/admin/reports',
      name: 'admin-reports',
      meta:{requiresAuth:true, roles:['admin'], title: 'Reports'},
      component: () => import('@/views/admin/dashboard/Reports.vue'),
    },
    {
      path: '/admin/invoices',
      name: 'admin-invoices',
      meta:{requiresAuth:true, roles:['admin'], title: 'Invoices'},
      component: () => import('@/views/admin/dashboard/Invoices.vue'),
    },
    {
      path: '/admin/payments',
      name: 'admin-payments',
      meta:{requiresAuth:true, roles:['admin'], title:'Payments'},
      component: () => import('@/views/admin/dashboard/Payments.vue'),
    },
    {
      path: '/admin/settings',
      name: 'admin-settings',
      meta:{requiresAuth:true, roles:['admin'], title: 'Settings'},
      component: () => import('@/views/admin/dashboard/Settings.vue'),
    },
  ]
  