import {initializeApp} from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { set_fcm_token } from '@/apis/notifications';
import { isUserLoggedIn } from '@/auth/utils'
import { eventBus } from '@/utils/eventbus'
// // Initialize Firebase
var firebaseConfig ={
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
const isUserLogin =  isUserLoggedIn()
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VPID }).then((currentToken) => {
    if (currentToken) {
      localStorage.setItem('fcmToken', currentToken)
      // Notification.requestPermission().then(function (permission) {
      //         console.log(permission)
      // })
    } else {

            // Show permission request UI
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
  
  // Handle incoming messages while app is in foreground
  onMessage(messaging, (payload) => {
    eventBus.$emit('onNotification', payload)
    // Handle the notification here
  });