export default [
    {
        path: '/admin/login',
        name: 'admin-auth-login',
        component: () => import('@/views/admin/auth/Login'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/admin/otp-verification/',
        name: 'admin-otp-verification',
        component: () => import('@/views/admin/auth/OtpVerification'),
        meta: {
                layout: 'full',
                resource: 'Auth',
            },
    },
    {
        path: '/admin/forget-password',
        name: 'admin-forget-password',
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
        component: () => import('@/views/admin/auth/ForgetPassword'),
    },
     {
        path: '/admin/reset-password/:email/:token',
        name: 'admin-reset-password',
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
        component: () => import('@/views/admin/auth/ResetPassword'),
    },
]